<template>
  <div class="container mt-4">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'home' }">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Início
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-for="(breadcrumb, key) in links"
        :key="key"
        :to="breadcrumb_route(breadcrumb)"
        :active="breadcrumb.active"
        >{{ breadcrumb.label }}</b-breadcrumb-item
      >
    </b-breadcrumb>
  </div>
</template>

<script>
import store from "@/store";
export default {
  computed: {
    links() {
      if (!this.$route.meta.breadcrumbs) {
        return (this.$route.matched || []).map(route => ({
          label: route.name,
          to: route.to
        }));
      }
      return this.$route.meta.breadcrumbs;
    }
  },
  methods: {
    breadcrumb_route(breadcrumb) {
      let to = { name: breadcrumb.to };

      if (breadcrumb.params) {
        let params = breadcrumb.params;
        let resolve_id = (obj, index) => {
          if (obj) {
            let indexes = index.split(".");
            if (indexes.length > 1) {
              let first = indexes.shift();
              return resolve_id(obj[first], indexes.join("."));
            }
            return obj[index];
          }
          return null;
        };

        to.params = {
          id: resolve_id(store.getters[params.getter], params.id)
        };
      }

      to.query = breadcrumb.query ? breadcrumb.query : null;

      return to;
    }
  }
};
</script>

<style></style>
