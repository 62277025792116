<template>
  <b-container fluid class="body_container" :class="$route.name">
    <header class="header container-fluid p-0" :class="{'header-gesplanos': !isPam}" v-if="showNavbar">
      <LayoutNavbar />
      <Breadcrumb v-if="!isHomePage" />
    </header>

    <main class="container" :class="{ 'offset-header': showNavbar }">
      <router-view />
    </main>

    <layout-footer class="mt-auto"></layout-footer>
  </b-container>
</template>

<script>
import LayoutNavbar from "./Default/LayoutNavbar";
import Breadcrumb from "./Default/Breadcrumb";
import LayoutFooter from "./Default/LayoutFooter.vue";
export default {
  components: {
    LayoutNavbar,
    Breadcrumb,
    LayoutFooter
  },
  computed: {
    showNavbar() {
      return this.$route.name !== "login";
    },
    isHomePage() {
      return this.$route.name === "home";
    },
    isPam() {
      return this.$route.meta?.isPam;
    }
  },
};
</script>

<style lang="scss">
.footer {
  line-height: 60px;
  background-color: #f5f5f5;
}
</style>
