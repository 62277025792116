<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="navbar">
    <div class="container mt-4">
      <router-link class="navbar-brand" :to="{ name: 'home' }">
        <img v-if="isPam" src="@/assets/img/logo-branco-pam.png" class="max-size-image" />
        <img v-else src="@/assets/img/gesplanos5.png" class="max-size-image" />
      </router-link>

      <div class="navbar__infomun text-center text-uppercase mx-auto">
        <strong>{{ this.$config.login_text }}</strong>
        <span class="text-gray-400">{{ this.$config.login_subtext }}</span>
      </div>

      <!-- <div class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <router-link
            v-for="(route, i) in routes"
            :key="i"
            :to="{ name: route.name }"
            class="nav-item"
            exact-active-class="active"
            custom
            v-slot="{ navigate }"
          >
            <li @click="navigate" @keypress.enter="navigate" role="link">
              {{ route.meta.title }}
            </li>
          </router-link>
        </ul>
      </div> -->
      <Sidebar />
    </div>
  </b-navbar>
</template>

<script>
import Sidebar from "./SideBar";

export default {
  components: {
    Sidebar,
  },
  computed: {
    routes() {
      return this.$router.options.routes.filter(
        (route) => route.meta && route.meta.showNavbar,
      );
    },
    isPam() {
      return this.$route.meta?.isPam;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  background: none !important;
  max-height: 150px;
}

.navbar-brand {
  margin-right: -100px !important;
  .max-size-image {
    max-width: 180px;
  }
}

.navbar__infomun {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 2em;
  position: relative;

  color: white;
}
</style>
