<template>
  <div>
    <b-icon
      v-b-toggle.sidebar
      icon="list"
      class="border border-secondary rounded menu-icon"
    />
    <b-sidebar id="sidebar" right backdrop shadow>
      <div class="px-3 py-2">
        <b-nav vertical class="w-20">
          <b-nav-item :to="{ name: 'home' }">Início</b-nav-item>
          <b-nav-item
            v-if="$can('strategic_plan:view')"
            :to="{ name: 'planos_estrategicos_list' }"
          >
            Planos Municipais
          </b-nav-item>
          <b-nav-item
            v-if="$can('client_config:view')"
            :to="{ name: 'clients_show' }"
          >
            Configurações
          </b-nav-item>
        </b-nav>

        <b-nav vertical class="w-100">
          <b-nav-text class="container">
            <small class="text-muted">CADASTROS</small>
          </b-nav-text>
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('physical_people:view')"
            :to="{ name: 'physical_people_list' }"
            >Pessoas Físicas</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('legal_people:view')"
            :to="{ name: 'legal_people_list' }"
            >Pessoas Jurídicas</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('organizational_structure:view')"
            :to="{ name: 'organizational_structures_tree' }"
            >Estruturas Organizacionais</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('strategic_plan:view')"
            :to="{ name: 'strategic_plans_list' }"
            >Planos Municipais</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('user:view')"
            :to="{ name: 'users_list' }"
            >Usuários</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('indicators:view')"
            :to="{ name: 'indicators_list' }"
            >Indicadores</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('super:view')"
            :to="{ name: 'source_list' }"
            >Fontes</b-nav-item
          >
          <b-nav-item
            class="nav-item rounded"
            v-if="$can('super:view')"
            :to="{ name: 'units_list' }"
            >Unidades</b-nav-item
          >
        </b-nav>

        <b-nav vertical class="w-100 mt-4" v-if="isSuperAdmin">
          <b-nav-text class="container">
            <small class="text-muted">PERMISSÕES</small>
          </b-nav-text>

          <b-nav-item
            class="nav-item rounded"
            v-if="$can('acl:view')"
            :to="{ name: 'acl_list' }"
            >Grupos de Usuários</b-nav-item
          >
        </b-nav>
      </div>

      <template #footer>
        <b-nav vertical class="px-3 mb-3">
          <b-nav-item>
            <div class="d-flex justify-content-between">
              <b-button
                :to="{ name: 'profile' }"
                variant="outline-secondary"
                class="rounded"
              >
                <font-awesome-icon :icon="['fas', 'user-alt']" />
                Perfil
              </b-button>

              <b-button
                @click="logout()"
                variant="outline-danger"
                class="rounded"
              >
                <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
                Sair
              </b-button>
            </div>
          </b-nav-item>
        </b-nav>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("acl", ["GetIsSuperAdmin"]),
    isSuperAdmin() {
      return this.GetIsSuperAdmin;
    },
  },
  methods: {
    ...mapActions("auth", ["ActionSignOut"]),
    ...mapActions("clients", ["ActionShow"]),
    async logout() {
      await this.ActionSignOut();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.nav-item {
  border-radius: 6px !important;
  a {
    color: var(--secondary) !important;
  }

  &:hover {
    background: rgba(var(--primary-rgb), 0.1);
    a {
      color: var(--primary) !important;
    }
  }

  a.btn {
    &:hover {
      background: var(--secondary);
      color: white !important;
    }
  }
}
</style>
